<template>
  <page-container class="portal-settings" :title="$t('pages.titles.portalSettings')">
    <div class="portal-settings__content">
      <titled-card v-for="formName of formsOrder" :key="formName" :title="formTitles[formName]">
        <base-form
          :ref="formName"
          v-model="formModels[formName]"
          :class="{ 'notification-sender-form': formName === FormName.NOTIFICATIONS_SENDER }"
          :disabled="isPending || pendingTasks[formName]"
          :configuration="formConfigurations[formName]"
        />

        <template #actions>
          <v-btn
            color="secondary"
            :disabled="areActionsButtonsDisabled[formName]"
            small
            depressed
            @click="resetSettings(formName)"
          >
            Отменить
          </v-btn>

          <v-btn
            class="ml-3"
            color="primary"
            :disabled="areActionsButtonsDisabled[formName]"
            :loading="pendingTasks[formName]"
            small
            depressed
            @click="saveSettings(formName)"
          >
            Применить
          </v-btn>
        </template>
      </titled-card>
    </div>
  </page-container>
</template>

<script>
  import _ from 'lodash'
  import { objectFromKeys } from 'geoportal/src/utils/func'
  import PendingTasksManagerMixin, { handlePendingTask } from 'geoportal/src/mixins/PendingTasksManagerMixin'
  import { createFormConfiguration, formFieldPresets } from '@/components/BaseForm/formHelpers'
  import { showMessage } from '@/utils/messages'
  import { emailRule, formatTextInputValue } from '@/utils/forms'
  import { getRefCmp } from 'geoportal/src/utils/vue'

  function parseEmailList(value) {
    if (!value) {
      return null
    }

    const emails = value.trim().split(/\s*,\s*/u)

    if (!emails.length) {
      return null
    }

    return emails
  }

  function emailListRule(value) {
    if (!value) {
      return true
    }

    const emails = parseEmailList()
    if (!emails) {
      return true
    }

    for (const email of emails) {
      const check = emailRule(email)
      if (check !== true) {
        return check
      }
    }

    return true
  }

  const FormName = Object.freeze({
    NOTIFICATIONS_RECEIVER: 'notificationsReceiver',
    NOTIFICATIONS_SENDER: 'notificationsSender',
    MAPBOX: 'mapbox',
    CAPTCHA: 'captcha'
  })
  const formConfigurations = {
    [FormName.NOTIFICATIONS_RECEIVER]: createFormConfiguration([
      [
        {
          id: 'feedback_email',
          label: 'Почта для получения писем',
          bind: {
            type: 'email',
            autocomplete: 'email',
            rules: [emailListRule],
            hint: 'Для указания нескольких получателей введите их, разделяя запятыми',
            persistentHint: true
          }
        },
        formFieldPresets.text
      ]
    ]),
    [FormName.NOTIFICATIONS_SENDER]: createFormConfiguration([
      [
        { id: 'host', label: 'Хост', style: { gridColumn: '1 / 5' }, bind: { autocomplete: 'url' } },
        formFieldPresets.text
      ],
      [{ id: 'port', label: 'Порт', style: { gridColumn: '5 / 7' } }, formFieldPresets.text],
      [
        {
          id: 'protocol',
          label: 'Протокол',
          style: { gridColumn: '7 / 9' },
          bind: {
            items: ['Plain', 'TLS', 'STARTTLS'].map((protocol) => ({
              text: protocol,
              value: protocol.toLowerCase()
            }))
          }
        },
        formFieldPresets.select
      ],
      [
        {
          id: 'login',
          label: 'Почта',
          style: { gridColumn: '1 / 5' },
          bind: { type: 'email', autocomplete: 'username', rules: [emailRule] }
        },
        formFieldPresets.text
      ],
      [{ id: 'password', label: 'Пароль', style: { gridColumn: '5 / 9' } }, formFieldPresets.password]
    ]),
    [FormName.MAPBOX]: createFormConfiguration([[{ id: 'mapbox_token', label: 'Токен' }, formFieldPresets.text]]),
    [FormName.CAPTCHA]: createFormConfiguration([
      [{ id: 'hcaptcha_secret', label: 'Secret' }, formFieldPresets.text],
      [{ id: 'hcaptcha_sitekey', label: 'Sitekey' }, formFieldPresets.text]
    ])
  }
  const formTitles = {
    [FormName.NOTIFICATIONS_RECEIVER]: 'Настройки обратной связи',
    [FormName.NOTIFICATIONS_SENDER]: 'Настройки отправителя уведомлений',
    [FormName.MAPBOX]: 'Настройки Mapbox',
    [FormName.CAPTCHA]: 'Настройки hCaptcha'
  }

  export default {
    name: 'PortalSettingsView',
    mixins: [PendingTasksManagerMixin],

    data() {
      return {
        FormName,
        formsOrder: [FormName.NOTIFICATIONS_RECEIVER, FormName.NOTIFICATIONS_SENDER, FormName.MAPBOX, FormName.CAPTCHA],
        formTitles,
        formConfigurations,
        initialSettings: objectFromKeys(Object.values(FormName), () => ({})),
        formModels: objectFromKeys(Object.values(FormName), () => ({}))
      }
    },

    computed: {
      hasNotChanges() {
        return Object.values(FormName).reduce((acc, formName) => {
          acc[formName] = _.isEqual(this.formModels[formName], this.initialSettings[formName])
          return acc
        }, {})
      },

      areActionsButtonsDisabled() {
        return Object.values(FormName).reduce((acc, formName) => {
          acc[formName] = this.isPending || !!this.pendingTasks[formName] || this.hasNotChanges[formName]
          return acc
        }, {})
      }
    },

    created() {
      this.fetchSettings()
    },

    methods: {
      setModels(settings) {
        const models = {
          [FormName.NOTIFICATIONS_RECEIVER]: { feedback_email: settings.portal.feedback_email },
          [FormName.NOTIFICATIONS_SENDER]: settings.portal.notifications,
          [FormName.MAPBOX]: _.pick(settings.portal, ['mapbox_token']),
          [FormName.CAPTCHA]: _.pick(settings.portal.captcha, _.map(formConfigurations[FormName.CAPTCHA], 'id'))
        }

        this.initialSettings = models
        this.formModels = _.cloneDeep(models)
      },

      @handlePendingTask()
      async fetchSettings() {
        const result = await this.$api.backend.settings.get_settings()
        this.setModels(result)
      },

      resetSettings(formName) {
        this.$set(this.formModels, formName, _.cloneDeep(this.initialSettings[formName]))
      },

      @handlePendingTask()
      @handlePendingTask('saveSettings')
      async saveSettings(formName) {
        const form = getRefCmp(this, formName)
        if (!form.validate()) {
          return
        }

        const formData = _.pickBy(
          _.mapValues(this.formModels[formName], formatTextInputValue),
          (value, key) => !_.isEqual(value, this.initialSettings[formName][key])
        )

        let requestData
        switch (formName) {
          case FormName.NOTIFICATIONS_RECEIVER:
            requestData = formData
            // reformat the emails value
            if (requestData.feedback_email) {
              const emails = parseEmailList(requestData.feedback_email)
              if (emails) {
                requestData.feedback_email = emails.join(',')
              }
            }
            break
          case FormName.NOTIFICATIONS_SENDER:
            requestData = { notifications: formData }
            break
          case FormName.CAPTCHA:
            requestData = { captcha: formData }
            break
          default:
            requestData = formData
        }

        const request = this.$api.backend.settings.update_settings({ portal: requestData })
        const result = await this.handlePendingTask(request, formName)
        this.setModels(result)
        showMessage('Настройки успешно обновлены')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .portal-settings {
    .portal-settings__content {
      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: 80px 20px;

      .titled-card {
        display: flex;
        flex-direction: column;

        &::v-deep .v-sheet {
          flex-grow: 1;
          padding-bottom: 4px !important;
        }
      }

      .notification-sender-form {
        grid-template-columns: repeat(8, 1fr);
      }
    }
  }
</style>
